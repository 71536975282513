.menu-items {
    color: #ccc3c3 !important;
    font-weight: 500;
    text-transform: uppercase;
}
.ant-drawer-title {
    color: aliceblue;
}
.ant-menu {
    background: #001529;
}
.li.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
    background: #95290f;
}
.header {
    display: flex;
}
.header-body {
    display: flex;
    align-items: center;
    border-bottom: solid 1px #e8e8e8;
    gap: 0px;
    justify-content: flex-start;
}
.traingle {
    width: 55px;
    height: 63px;
    position: relative;
    background: linear-gradient(120deg, #001529 50%, #000000 48%);
}
@media (max-width: 768px) {
    .header-body {
        justify-content: space-between;
        gap: 30px;
    }
   .traingle {
    display: none;
   }
}