.video-player-container {
    margin: 0 auto; /* Center the container horizontally */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Add a subtle shadow for depth */
    width: 1020px;
    height: 600px;
    background: black;
    padding: 5px 0px 5px 0px;
  }

.video-player-container-mobile {
    margin: 0 auto; /* Center the container horizontally */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Add a subtle shadow for depth */
    width: auto;
    height: auto;
    background: black;
  }

  .loading-placeholder {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2rem;
    color: #333;
  }