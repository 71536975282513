/* styles.css */

.custom-select .ant-select-selector {
    background: linear-gradient(135deg, #807979 0%, #000000 100%); /* Gradient background */
    border-radius: 12px; /* Rounded corners */
    padding: 10px;
    border: none; /* Remove default border */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow */
    transition: all 0.3s ease-in-out; /* Smooth transition */
  }
  
  .custom-select .ant-select-selector:hover {
    transform: scale(1.02); /* Slight zoom effect on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
  }
  
  .custom-select .ant-select-arrow {
    color: #fff; /* White dropdown arrow */
    font-size: 18px;
  }
  
  .custom-select .ant-select-selection-item {
    font-weight: bold;
    font-size: 16px;
    color: #fff; /* White text */
  }
  
  .custom-select .ant-select-dropdown {
    border-radius: 12px; /* Rounded corners for the dropdown */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Dropdown shadow */
    background: #fff; /* White dropdown background */
    padding: 10px; /* Padding around dropdown items */
  }
  
  .custom-select .ant-select-item {
    color: #333; /* Text color for dropdown items */
    font-size: 16px;
    padding: 10px;
    transition: background-color 0.3s ease-in-out; /* Smooth background color transition */
  }
  
  .custom-select .ant-select-item:hover {
    background-color: #ff9a9e; /* Highlight color on hover */
    color: #fff; /* White text on hover */
  }
  
  .custom-select .ant-select-item-option-selected {
    background-color: #fad0c4; /* Selected item color */
    color: #333; /* Selected text color */
  }
  


.movie-player {
    position: relative;
    background-color: #000;
}
.grid-style {
    width:'100%' !important;
    text-align: center;
    margin: 4px;
    cursor: grabbing;
    font-size:18px;
    background-color: #5d5d5d;
    padding: 15px;
    border-radius: 5px;
}
.grid-style-mobile {
    background-color: #5d5d5d;
    padding: 5px;
    border-radius: 5px;
}
.grid-style:hover {
    background-color: #575a5e;;
    color: aliceblue;
    border: none;
    border-radius: 5px;
}
.category-row {
    margin-left: 15px;
}
.category-link {
    color: #000000 !important;
    font-size: medium;
}
.ant-select-item.ant-select-item-option {
    margin: 3px;
    border-radius: 10px;
    background: linear-gradient(135deg, #b19e9f 0%, #461616 100%); /* Gradient background */
}