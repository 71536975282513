body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  overflow-x: hidden;
}
.ant-card .ant-card-meta-title {
  white-space: normal;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.ant-layout, .ant-layout-content, .ant-card, .ant-card-body, .ant-modal, .ant-modal-body, .ant-popover {
  background-color: #464646  !important;
}
a {
  color: #041b4b !important;
}
.movie-image:hover {
  transform: scale(1.1);
  opacity: 0.7;
  border: 2px solid #5a0707;
}
.play-button {
  width: 60px;
  height: 60px;
  background-color: #5a0707;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.3s ease;
  position: relative;
}
.play-button::before {
  content: '';
  width: 0;
  height: 0;
  border-left: 15px solid white;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}
.episode-icon {
  position: absolute;
  top: 4px;
  left: 4px;
  background-color: #5a0707;
  color: white;
  padding: 6px 5px;
  border-radius: 20px;
  font-size: 11px;
}
@media (max-width: 768px) {
  .episode-icon {
    padding: 6px 4px;
    border-radius: 20px;
    font-size: 6px;
  }
  .play-button {
    width: 30px;
    height: 30px;
    position: relative;
  }
}

